

@font-face {
  font-family: 'PP Neue Montreal Bold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/ppneuemontreal-bold.otf") format("otf"), url("./assets/ppneuemontreal-bold.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}
@font-face {
  font-family: 'PP Neue Montreal SemiBold';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/ppneuemontreal-semibolditalic.otf") format("otf"), url("./assets/ppneuemontreal-semibolditalic.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}
@font-face {
  font-family: 'PP Neue Montreal Italic';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/ppneuemontreal-italic.otf") format("otf"), url("./assets/ppneuemontreal-italic.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}
@font-face {
  font-family: 'PP Neue Montreal Medium';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/ppneuemontreal-medium.otf") format("otf"), url("./assets/ppneuemontreal-medium.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}
@font-face {
  font-family: 'PP Neue Montreal';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("./assets/ppneuemontreal-book.otf") format("otf"), url("./assets/ppneuemontreal-book.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}
@font-face {
  font-family: 'PP Neue Montreal Thin';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/ppneuemontreal-thin.otf") format("otf"), url("./assets/ppneuemontreal-thin.woff") format("woff");
  unicode-range: U+20, U+21, U+23, U+26-29, U+2C-33, U+35-38, U+3A, U+3F,
    U+41-57, U+61-7A, U+A9, U+E9, U+2013, U+2014, U+2026, U+2AF6, U+1F44B;
}